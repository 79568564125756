<template>

  <el-modal size="sm" :title="editing ? $t('modules.wiki.tokens.actions.edit') : $t('modules.wiki.tokens.actions.add')" id="wiki-token-edit">
    <div v-if="token">
      <el-alert type="warning" v-if="token && token.references && token.references.length > 0">
        {{ $t('modules.wiki.tokens.used_warning') }}
        <ul class="py-1 m-0 pl-3">
          <li v-for="ref in token.references">
            <router-link tag="a" :to="{name: 'wiki.content', params: { world: $route.params.world, content: ref.id } }" target="_blank">{{ ref.name }} <sup class="small"><i class="small icon-new-tab"></i></sup></router-link>
          </li>
        </ul>
      </el-alert>
      <form @submit.prevent="editToken()">
        <div class="row mb-2">
          <div class="col-12">
            <label for="key">{{ $t('modules.wiki.tokens.fields.key') }}</label>
            <input :disabled="token.id" v-model="token.key" required type="text" class="form-control" id="key">
          </div>
        </div>
        <div class="row mb-2" v-if="token.type === 'string'">
          <div class="col-12">
            <label for="value">{{ $t('modules.wiki.tokens.fields.value') }}</label>
            <input v-model="token.data.value" required type="text" class="form-control" id="value">
          </div>
        </div>
        <div class="text-center">
          <button v-if="!editLoading" type="submit" class="btn btn-success text-white">{{ editing ? $t('common.edit') : $t('common.add') }}</button>
          <div v-if="editLoading" class="text-primary pt-1 pb-2"><loader /></div>
        </div>
      </form>
    </div>
  </el-modal>

</template>

<script>

import store from '@/store'

import {
  WIKI_CREATE_TOKEN,
  WIKI_GET_TOKENS,
  WIKI_SET_TOKEN,
  WIKI_DELETE_TOKEN,
} from '../mutations-types'

/**
 * @group _ Module Wiki
 * Modal to edit token
 */
export default {
  name: 'WikiTokensEditModal',
  tag: 'wiki-tokens-edit-modal',
  props: {
    token: {
      required: true,
      validator: prop => typeof prop === Object || prop === null
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      editLoading: false,
    }
  },
  computed: {
    editing () {
      return this.token && this.token.id
    }
  },
  methods: {
    editToken () {
      this.editLoading = true
      this.$store.dispatch('wiki/' + (this.editing ? WIKI_SET_TOKEN : WIKI_CREATE_TOKEN), {
        id: this.editing ? this.token.id : null,
        world_id: this.$route.params.world,
        key: this.token.key,
        value: this.token.data.value
      }).then(data => {
        this.editLoading = false
        this.notifSuccess(this.editing ? this.$t('modules.wiki.tokens.messages.edit_success') : this.$t('modules.wiki.tokens.messages.add_success'))
        this.callback ? this.callback() : ''
        this.closeModal('wiki-token-edit')
      })
      .catch(error => {
        this.editLoading = false
        this.notifError(this.editing ? this.$t('modules.wiki.tokens.messages.edit_error') : this.$t('modules.wiki.tokens.messages.create_error'))
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
